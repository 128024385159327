/* eslint-disable */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* ------------------------------------------------------------------------*/

const ContactModal = (props) => {
  // getting logged in user's token here
  const token = localStorage.getItem("token_v2");
  const params = useParams();

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [selectedReason, setSelectedReason] = useState();
  const [reason, setReason] = useState("");
  const [selectedHistory, setSelectedHistory] = useState();
  const [champHistory, setChampHistory] = useState("");
  const [messageWarning, setMessageWarning] = useState(false);
  const [reasonWarning, setReasonWarning] = useState(false);
  const [realtionWarning, setRealtionWarning] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // custom style for select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #D7D7D7",
      color: "#495057",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "18px",
      padding: "0px",
      margin: "2px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#fff",
      height: 50,
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#495057",
        padding: "0px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#495057" };
      return Object.assign(base, changes);
    },
  };

  // contact options
  const contactOptions = [
    { label: t("Business Networking"), value: "Business Networking" },
    { label: t("Relational Connect"), value: "Relational Connect" },
    { label: t("Promotional Connect"), value: "Promotional" },
    { label: t("None of the above"), value: "None of the above" },
  ];

  // relation option
  const knowOptions = [
    { label: t("Friends"), value: "Friends" },
    { label: t("Family"), value: "Family" },
    { label: "Facebook", value: "Facebook" },
    { label: "Linkedin", value: "Linkedin" },
    { label: t("Other Source"), value: "Other Source" },
  ];

  //function for select contact
  const selectContactHandler = (val) => {
    setSelectedReason();
    setReason(val.value);
  };

  //function for select reason
  const selectHistoryHandler = (val) => {
    setSelectedHistory();
    setChampHistory(val.value);
  };

  //function for send champ connect modal
  const connectChampionHandler = async () => {
    if (reason == "" || champHistory == "") {
      if (reason == "") {
        setReasonWarning(true);
      } else {
        setReasonWarning(false);
      }
      if (champHistory == "") {
        setRealtionWarning(true);
      } else {
        setRealtionWarning(false);
      }
    } else {
      setReasonWarning(false);
      setRealtionWarning(false);
      setIsSending(true);

      let requestURL =
        url.API_BaseUrl_V2 + url.API_CHAMPION_CONTACT + `?token=${token}`;

      let connectData = {
        reason: reason,
        source: champHistory,
        message,
        member: params.id,
      };

      console.log(connectData);

      try {
        const response = await postData(requestURL, connectData);

        setIsSending(false);

        console.log(response);

        if (response.status) {
          let contactModal = document.querySelector("#contact_owner");
          let modal = bootstrap.Modal.getInstance(contactModal);
          modal.hide();

          resetHandler();

          let myModal = new bootstrap.Modal(
            document.getElementById("champion_connect_thanks_mdl")
          );

          myModal.show();

          props.getChampionsData(params.id);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setMessage("");
    setChampHistory("");
    setReason("");
    setSelectedReason(null);
    setSelectedHistory(null);
    setMessageWarning(false);
    setRealtionWarning(false);
    setReasonWarning(false);
  };

  return (
    <div className="conatct_modal">
      <div className="modal fade" id="contact_owner">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="owner_hdngOuter">
                {props.championData ? (
                  <h5>
                    {t("Contact")} -
                    <b>
                      {" "}
                      {props.championData.name} {props.championData.surname}
                    </b>
                    {/* <span className="online"></span> */}
                  </h5>
                ) : null}
                <button
                  type="button"
                  className="close ylw_closeBtn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  <i className="material-icons-outlined">close</i>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="owner_detailsOuter">
                {/* ---- reason block start ---- */}
                <div className="input_fill jobinput_fill">
                  {props.championData ? (
                    <label>
                      {t("Reason of contact")} - {props.championData.name}{" "}
                      {props.championData.surname}{" "}
                    </label>
                  ) : (
                    <label>{t("Reason of contact")} - </label>
                  )}

                  <div className="model_selectBx">
                    <Select
                      className="select2"
                      placeholder={t("Select Reason")}
                      options={contactOptions}
                      classNamePrefix="select2-selection"
                      value={selectedReason}
                      onChange={(val) => selectContactHandler(val)}
                    />
                  </div>
                  {reasonWarning && (
                    <p className="text-danger mt-2 mb-0">
                      * {t("Please select reason")}
                    </p>
                  )}
                </div>
                {/* ---- reason block end ---- */}

                {/* ---- relation block start ---- */}
                <div className="input_fill jobinput_fill">
                  {props.championData ? (
                    <label>
                      {t("How you know")} - {props.championData.name}{" "}
                      {props.championData.surname}
                    </label>
                  ) : (
                    <label>{t("How you know")} - </label>
                  )}

                  <div className="model_selectBx">
                    <Select
                      className="select2"
                      placeholder={t("Select Relation")}
                      options={knowOptions}
                      classNamePrefix="select2-selection"
                      value={selectedHistory}
                      onChange={(val) => selectHistoryHandler(val)}
                    />
                  </div>
                  {realtionWarning && (
                    <p className="text-danger mt-2 mb-0">
                      * {t("Please select relation")}
                    </p>
                  )}
                </div>
                {/* ---- relation block end ---- */}

                {/* ---- message block start ---- */}
                <div className="input_fill jobinput_fill description_fill">
                  {props.championData ? (
                    <label>
                      {t("Message for")} - {props.championData.name}{" "}
                      {props.championData.surname}
                    </label>
                  ) : (
                    <label>{t("Message for")} - </label>
                  )}
                  <textarea
                    className="form-control pt-2"
                    placeholder={t("Message for contact")}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {messageWarning && (
                    <p className="text-danger mt-2 mb-0">
                      * {t("Please give message")}
                    </p>
                  )}
                </div>
                {/* ---- message block end ---- */}

                {/* ---- button block start ---- */}
                <div className="input_fill jobinput_fill">
                  <button
                    type="button"
                    className="basic_btn ylw_btn"
                    onClick={connectChampionHandler}
                    disabled={isSending ? true : false}
                    style={{
                      cursor: isSending ? "not-allowed" : "pointer",
                    }}
                  >
                    {t("Sent")}
                    {isSending && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
                {/* ---- button block end ---- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
