/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import helper functions
import * as url from "helpers/url_helper";

// import Gateway
import { getData_v2 } from "utils/Gateway";

const FaqSearchResult = ({ 
            searchQuery,
            resetQuery,
            setResetQuery         
            }) => {


    // user information
    const token = localStorage.getItem("token_v2");
    const userInfo = JSON.parse(localStorage.getItem("userInfo_v2"));


    // states for faq search result
    const [faqData, setFaqData] = useState([]);

    // get request to API to fetch faq data
    const getFaqData = async () => {

        // fetch data
        try {

            let requestUrl = url.API_BaseUrl_V2 + url.API_FAQ_LIST + `?token=${token}`;

            if(searchQuery.length > 2 && resetQuery === false){
                requestUrl += `&query=${searchQuery}`;
            }

            console.log('requestUrl',requestUrl);

            const response = await getData_v2(requestUrl);

            console.log('FAQ data response',response);

            if(response.status === true){
                setFaqData(response.data);
            }

        } catch (error) {   
            console.log(error.message);
        }

    }

    // useeffect to fetch data on change of searchQuery or resetQuery
    useEffect(() => {

        // fetch data
        if(searchQuery.length > 2 || resetQuery){

            console.log('searchQuery',searchQuery);
            console.log('resetQuery',resetQuery);

            getFaqData();

            setResetQuery(false); // after reload, set this false
        }        
    
    }, [searchQuery, resetQuery]);


    // by default load all faq data
    useEffect(() => {

        // fetch data
        getFaqData();

    }, []);





    return (
        <section className="faq-wrapper py-5">
            <div className="container">
                <div className="accordion-filter mb-4">
                    <div className="row align-items-center">
                        <div className="col-lg-3 mb-2 mb-lg-0">
                            <p>Found {faqData.length} result </p>
                        </div>
                        <div className="col-lg-9">
                            <div className="d-flex flex-column flex-md-row gap-2 justify-content-lg-end">
                                <div className="dropdown parent">
                                    <button className="dropdown-toggle d-flex justify-content-between w-100 w-md-auto shadow-none" type="button" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">Select by Subsistema</button>
                                    <ul className="dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                        <li>
                                            <div className="dropdown children dropend">
                                                <Link to="#" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">SITUATION DATA DISPLAY</Link>
                                                <ul className="dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                                    <li><Link to="#" className="dropdown-item">Mensajeria</Link></li>
                                                    <li><Link to="#" className="dropdown-item">Funcionalidades</Link></li>
                                                    <li><Link to="#" className="dropdown-item">Vigilancia</Link></li>
                                                </ul>
                                            </div>
                                        </li>
                            
                                        <li><Link to="#" className="dropdown-item">DATA ANALYSIS TOOL</Link></li>
                                        <li><Link to="#" className="dropdown-item">DATA RECORDER FACILITY</Link></li>
                                        <li><Link to="#" className="dropdown-item">BILLING</Link></li>
                                        <li><Link to="#" className="dropdown-item">SYSTEM ADMINISTRATION MANUAL</Link></li>
                                    </ul>
                                </div>
                                <div className="dropdown parent">
                                    <button className="dropdown-toggle d-flex justify-content-between w-100 w-md-auto shadow-none" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">Select by Subsistema</button>
                                    <ul className="dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                        <li><Link to="#" className="dropdown-item">Group</Link></li>
                                        <li><Link to="#" className="dropdown-item">Events</Link></li>
                                        <li><Link to="#" className="dropdown-item">Challenge</Link></li>
                                        <li><Link to="#" className="dropdown-item">Experts</Link></li>
                                    </ul>
                                </div>
                                <div className="dropdown parent">
                                    <button className="dropdown-toggle d-flex justify-content-between w-100 w-md-auto shadow-none" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">Select by Subsistema</button>
                                    <ul className="dropdown-menu w-100 w-md-auto border-0 rounded-0">
                                        <li><Link to="#" className="dropdown-item">Atm</Link></li>
                                        <li><Link to="#" className="dropdown-item">Java</Link></li>
                                        <li><Link to="#" className="dropdown-item">Python</Link></li>
                                        <li><Link to="#" className="dropdown-item">Ats</Link></li>
                                        <li><Link to="#" className="dropdown-item">Air</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="faqs-group d-flex flex-column gap-5">
                    {faqData.length === 0 ? (
                        <div className="faqs">
                            <h3>Loading...</h3>
                            <ul className="d-flex flex-column gap-2">
                                <li className="glow-placeholder"></li>
                                <li className="glow-placeholder"></li>
                                <li className="glow-placeholder"></li>
                            </ul>
                        </div>
                    ) : (
                       
                        <div className="faqs">
                            <h3>Globals</h3>
                            <ul className="d-flex flex-column gap-2">
                                {faqData.length > 0 && faqData.map((faq, index) => (
                                    <li key={index}>
                                        <Link to={`/faq/details/${faq._id}`} className="d-flex align-items-center justify-content-between gap-3">
                                            <span className="d-block">{faq.question}</span>
                                            <span className="d-flex align-items-center justify-content-center material-icons-outlined">arrow_forward</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                     
                    )}
                </div>

                {/* pagination */}
                <ul className="pagination d-flex align-items-center justify-content-center gap-3 mt-4">
                    <li className="prev">
                        <Link to="#" className="d-flex align-items-center justify-content-center">
                            <span className="d-block material-icons-outlined">chevron_left</span>
                        </Link>
                    </li>
                    <li className="page active">
                        <Link to="#" className="d-flex align-items-center justify-content-center">1</Link>
                    </li>
                    <li className="page">
                        <Link to="#" className="d-flex align-items-center justify-content-center">2</Link>
                    </li>
                    <li className="page">
                        <Link to="#" className="d-flex align-items-center justify-content-center">3</Link>
                    </li>
                    <li className="page">...</li>
                    <li className="page">
                        <Link to="#" className="d-flex align-items-center justify-content-center">98</Link>
                    </li>
                    <li className="page">
                        <Link to="#" className="d-flex align-items-center justify-content-center">99</Link>
                    </li>
                    <li className="next">
                        <Link to="#" className="d-flex align-items-center justify-content-center">
                            <span className="d-block material-icons-outlined">chevron_right</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default FaqSearchResult;