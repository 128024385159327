import React from 'react';
import { Link } from 'react-router-dom';

const FaqDetails = () => {
    return (
        <>
            {/* Faq details */}
            <h1>¿Hay una prueba gratuita disponible?</h1>

            <ul className="tags d-flex align-items-center flex-wrap gap-2 mb-3">
                <li className="fw-bold">Tags</li>
                <li className="tag px-3 py-2 rounded-8">Lobees</li>
                <li className="tag px-3 py-2 rounded-8">SportsMoon</li>
                <li className="tag px-3 py-2 rounded-8">Crowcol</li>
            </ul>
            <p>This time the seller send me green color T-shirt instead of Grey. I did not make a fuzz as the overall quality looked satisfying. However, just after 2 wash this is the outcome, the stitching has come off already and strains of threads has started coming out from everywhere.</p>
            <p>I was going to order another T-shirt from the same seller but not wont even think of it. Quality cannot be judged by the comments alone, you need to try it and this is what I got for it. This time the seller send me green color T-shirt instead of Grey. I did not make a fuzz as the overall quality looked satisfying. However, just after 2 wash this is the outcome, the stitching has come off already and strains of threads has started coming out from everywhere.</p>
            <p>I was going to order another T-shirt from the same seller but not wont even think of it. Quality cannot be judged by the comments alone, you need to try it and this is what I got for it.</p>
            <Link to="#" className="basic_btn ylw_btn w-auto mt-4">Any Doubt</Link>
            
            {/* related Faqs */}
            <div className="relate-to mt-5">
                <h3 className="mb-3">Related to:</h3>
                <h6 className="mb-2">Course</h6>
                <ul className="mb-3">
                    <li>
                        <Link to="#" className="d-flex align-items-center gap-2">
                            <span className="d-block material-icons-outlined">link</span>
                            <span className="d-block text-decoration-underline">Amazon Inventory services latest course 2024</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className="d-flex align-items-center gap-2">
                            <span className="d-block material-icons-outlined">link</span>
                            <span className="d-block text-decoration-underline">Amazon Inventory services latest course 2024</span>
                        </Link>
                    </li>
                </ul>
                <h6 className="mb-2">Post</h6>
                <ul className="mb-3">
                    <li>
                        <Link to="#" className="d-flex align-items-center gap-2">
                            <span className="d-block material-icons-outlined">link</span>
                            <span className="d-block text-decoration-underline">Call for drone controllers latest Mavic</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className="d-flex align-items-center gap-2">
                            <span className="d-block material-icons-outlined">link</span>
                            <span className="d-block text-decoration-underline">Inventory management services latest course 2024</span>
                        </Link>
                    </li>
                </ul>
                <h6 className="mb-2">Experts</h6>
                <ul>
                    <li>
                        <Link to="#" className="d-flex align-items-center gap-2">
                            <span className="d-block material-icons-outlined">link</span>
                            <span className="d-block text-decoration-underline">Victor Herrero</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className="d-flex align-items-center gap-2">
                            <span className="d-block material-icons-outlined">link</span>
                            <span className="d-block text-decoration-underline">Hugo Herrero</span>
                        </Link>
                    </li>
                </ul>
            </div>
        
        </>
    );
};

export default FaqDetails;