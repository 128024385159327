/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { postData, postData_v2 } from "utils/Gateway";
import * as url from "helpers/url_helper";
import { useTranslation } from "react-i18next";

//import images
import loginPic from "assets/CourseAssets/images/login-pic.jpg";

// import custom component
import Header from "common/Header";
import Footer from "common/Footer";
import ForgotPassModal from "components/SignupComponents/Modals/ForgotPassModal";
import ValidateUserModal from "components/LoginComponents/Modal/ValidateUserModal";
import EmailCodeSendModal from "components/SignupComponents/Modals/EmailCodeSendModal";
import ContactAdminModal from "components/LoginComponents/Modal/ContactAdminModal";
import ErrorMessageModal from "../../components/LoginComponents/Modal/ErrorMessageModal";

const Login = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [showPassword, setShowPassword] = useState(true);
  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  /*----- UseState to send username and password for Login ----*/
  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // function to handle the logIn process
  const LogInHandler = async (e) => {
    e.preventDefault();

    if (userEmail == "" || userPassword == "") {
      if (userEmail == "") {
        setEmailWarning(true);
      } else {
        setEmailWarning(false);
      }
      if (userPassword == "") {
        setPasswordWarning(true);
      } else {
        setPasswordWarning(false);
      }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (userEmail.match(mailformat)) {
        setNotValidEMail(false);
        setIsLoading(true);

        try {
          // node login check ----------------
          let userData_v2 = {
            email: userEmail,
            password: userPassword,
          };

          let requestURL_v2 = url.API_BaseUrl_V2 + url.API_LOGIN_V2;

          const response_v2 = await postData_v2(requestURL_v2, userData_v2);

          console.log("response V2-->", response_v2);

          if (response_v2.status) {
            // assigning the loggedin user's id,token,all data to the browser's local storage.
            localStorage.setItem("token_v2", response_v2.token);
            localStorage.setItem(
              "userInfo_v2",
              JSON.stringify(response_v2.data)
            );
            localStorage.setItem(
              "userID_v2",
              JSON.stringify(response_v2.data._id)
            );
            //window.location.reload();
          }

          // symfony login check ----------------
          let userData = {
            usemail: userEmail,
            uspass: userPassword,
          };

          let requestURL =
            url.API_BaseUrl +
            url.API_LoginUrl +
            "?usemail=" +
            userEmail +
            "&uspass=" +
            userPassword;

          const response = await postData(requestURL, userData);

          console.log("login response", response);

          if (response.status) {
            if (response.adminstatus) {
              // assigning the received values to the browser's local storage.
              localStorage.setItem("token", response.token);
              localStorage.setItem("userInfo", JSON.stringify(response));
              // assigning the loggedin user's id to the browser's local storage.
              localStorage.setItem("userID", JSON.stringify(response.id));
              localStorage.setItem("language", response.language);
              i18n.changeLanguage(response.language);

              // history.push("/");
            } else {
              let myModal = new bootstrap.Modal(
                document.getElementById("contact_admin_modal")
              );
              myModal.show();
            }
          } else {
            let myModal = new bootstrap.Modal(
              document.getElementById("login_error_mdl")
            );
            myModal.show();
          }

          setIsLoading(false);
          resetState();
        } catch (error) {}
      } else {
        setNotValidEMail(true);
      }
    }
  };

  // function to reset the useState containing the email and password text fields
  const resetState = () => {
    setUserEmail("");
    setUserPassword("");
  };

  //function for display email modal for validate code
  const displayEmailModal = (data) => {
    if (data) {
      let myModal = new bootstrap.Modal(
        document.getElementById("sent_code_modal")
      );
      myModal.show();
    }
  };

  // useEffect for cheking the login token available or not with refferel url
  // useEffect(() => {
  //   if (token) {
  //     if (startingUrl == null) {
  //       if (document.referrer == "" || document.referrer == null) {
  //         window.location.href = "/";
  //       } else {
  //         if (document.referrer.split("/").pop() == "logout") {
  //           window.location.href = "/";
  //         } else {
  //           window.location.href = document.referrer;
  //         }
  //       }
  //     } else {
  //       window.location.href = startingUrl;
  //     }
  //   }
  // }, [token]);

  useEffect(() => {
    document.title = "Login";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="login_area">
        <div className="container">
          <div className="login_bxArea">
            <div className="signupleft_pnl">
              <div className="login_innr">
                <div className="login_hdng">
                  <h2>{t("Log in")}</h2>
                  <h5>{t("Please enter details to proceed")}</h5>
                </div>

                <form onSubmit={LogInHandler}>
                  <div className="login_fillOuter">
                    <div className="input_fill jobinput_fill">
                      <label>{t("Email")}</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("Enter email")}
                        value={userEmail}
                        onChange={(e) => {
                          setEmailWarning(false);
                          setUserEmail(e.target.value);
                        }}
                      />
                      {emailWarning && (
                        <p className="text-danger mt-1 mb-0">
                          * {t("Please enter email")}
                        </p>
                      )}
                      {notValidEMail && (
                        <p className="text-danger mt-1 mb-0">
                          * {t("Please enter valid email")}
                        </p>
                      )}
                    </div>
                    <div className="input_fill jobinput_fill">
                      <label>{t("Password")}</label>
                      <div className="eye_btnfill">
                        <input
                          type={showPassword ? "password" : "text"}
                          className="form-control"
                          placeholder={t("Enter Password")}
                          value={userPassword}
                          onChange={(e) => {
                            setPasswordWarning(false);
                            setUserPassword(e.target.value);
                          }}
                        />
                        <Link to="#" className="copy_link" onClick={toggleText}>
                          <span className="material-icons-outlined">
                            visibility
                          </span>
                        </Link>
                      </div>
                      {passwordWarning && (
                        <p className="text-danger mt-1 mb-0">
                          * {t("Please enter password")}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="trms_check login_checkouter">
                    <label className="radio-check">
                      {/* <input type="checkbox" />
                      <span></span>
                      <p>
                        {t("Agree with the")}
                        <Link to="/terms-conditions">T&amp;C</Link>
                      </p> */}
                    </label>
                    <div className="forgot_links">
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#forgot_password_modal"
                      >
                        {t("Forgot Password")}
                      </Link>
                    </div>
                  </div>
                  <div className="login_submit">
                    <button
                      type="submit"
                      className="basic_btn ylw_btn"
                      disabled={isLoading ? true : false}
                      style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                    >
                      {t("Sign in")}
                      {isLoading && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                  <div className="create_userOuter">
                    <div className="create_text">
                      <p>
                        {t("Already have an activation code?")}
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#validate_user_modal"
                        >
                          {t("Validate now")}
                        </Link>
                      </p>
                    </div>
                    <div className="or_text">
                      <span>{t("or")}</span>
                    </div>
                    <div className="create_text">
                      <p>
                        {t("Do not have an account?")}
                        <Link to="/signup">{t("Create one")}</Link>
                      </p>
                    </div>
                    <div className="or_text">
                      <span>{t("or")}</span>
                    </div>
                    {/* <div className="linkedin_btn">
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#contact_admin_modal"
                      >
                        <span>
                          <img src="images/gmail.svg" alt="" />
                        </span>
                        {t("Login with Gmail")}
                      </Link>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
            <div className="signupright_pnl">
              <div className="signup_pic">
                <figure>
                  <img src={loginPic} alt="login" />
                </figure>
                <div className="login_caption">
                  <h2>
                    <span>{t("Discover Indra")} </span> ATM Ecosystem
                  </h2>
                  <p>
                    {t(
                      "More than 25% of the world s total airspace is managed with Indra s Automation Systems"
                    )}
                  </p>
                  <div className="explore_btn">
                    <Link
                      className="basic_btn ylw_btn"
                      to="#"
                      onClick={() => {
                        document.body.classList.add("contact_open");
                      }}
                    >
                      {t("Contact Administrator")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ValidateUserModal />
      <ForgotPassModal displayEmailModal={displayEmailModal} />
      <EmailCodeSendModal displayEmailModal={displayEmailModal} />
      <ContactAdminModal />
      <ErrorMessageModal />
    </>
  );
};

export default Login;
