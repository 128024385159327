/* eslint-disable */
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import * as url from "helpers/url_helper";

import SentModal from 'common/Modals/SentModal';

/*import url and gateway methods */
import { getData_v2, postData_v2, uploadMultipleFile_v2 } from "utils/Gateway";


const AskQuestion = () => {

    // user information
    const token = localStorage.getItem("token_v2");
    const userInfo = JSON.parse(localStorage.getItem("userInfo_v2"));

    //console.log('userInfo', userInfo);


    // component states
    const [faqsubject, setFaqSubject] = useState('');
    const [faqdescription, setFaqDescription] = useState('');

    const [isUploading, setisUploading] = useState(false);
    const [uploadedFileCount, setUploadedFileCount] = useState(0);
    const [messageWarning, setmessageWarning] = useState(false);
    const [fileIsBig, setFileIsBig] = useState(false);

    const [uploadedFiles, setuploadedFiles] = useState([]);
    const [uploadedFileIds, setuploadedFileIds] = useState([]);

    // validation states for each field
    const [faqSubjectError, setFaqSubjectError ] = useState(false);
    const [faqDescriptionError, setFaqDescriptionError ] = useState(false);
    const [isValidate, setIsValidate] = useState(true);


    // function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        setIsValidate(true);
        
        // validate form fields
        if (faqsubject === '') {
            setFaqSubjectError(true);

            setIsValidate(false);
        } else {
            setFaqSubjectError(false);
        }

        if (faqdescription === '') {
            setFaqDescriptionError(true);

            setIsValidate(false);
        } else {
            setFaqDescriptionError(false);
        }

        if(isValidate) {
            saveQuestion();
        }
    };

    //function to save question using API
    const saveQuestion = async () => {

        console.log('question is validate', isValidate);

        // check if validate is true
        if (isValidate) {
            try {
                const requestUrl = url.API_BaseUrl_V2 + url.API_CREATE_FAQ + `?token=${token}`;
                //const requestUrl = url.API_BaseUrl_V2 + url.API_DEMO_POST_V2 + `?token=${token}`;

                const data = {
                    subject: faqsubject,
                    question: faqdescription,
                    files: uploadedFileIds,
                };

                console.log('data', data);

                const response = await postData_v2(requestUrl, data);

                console.log(response);

                if (response.status) {

                    // close question modal
                    let questionModel = bootstrap.Modal.getInstance(document.getElementById("askQuestion"));
                    if (questionModel) {
                        questionModel.hide();
                    }

                    // show success message
                    let thanksModal = new bootstrap.Modal(
                    document.getElementById("sent_mdl")
                    );
                    thanksModal.show();
                
                                
                    setFaqSubject('');
                    setFaqDescription('');
                    setuploadedFiles([]);
                    setuploadedFileIds([]);
                    setmessageWarning(true);
                }
            } catch (error) {
                console.log(error.message);
            }
        
        }
    }


     //function upload multiple files
    const fileUploadHandler = async (e) => {
        try {
        setFileIsBig(false);

        setisUploading(true);

        const files = e.target.files;

        setUploadedFileCount(files.length);

        console.log('files', files);
        
        let fileProceed = true;
        // let fileProceed = false;

        // if (files.length > 0) {
        //     for (let i = 0; i < files.length; i++) {
        //     let f = files[i];

        //     if (f.size <= 12500000) {
        //         fileProceed = true;
        //     } else {
        //         fileProceed = false;
        //     }
        //     }
        // }

        if (fileProceed) {
            const requestUrl =
            url.API_BaseUrl_V2 + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

            const response = await uploadMultipleFile_v2(requestUrl, files);

            setisUploading(false);

            console.log(requestUrl);
            console.log(response);

            if (response.status) {
            /*----- assign uploaded files with path for display start ------*/
            const uploadedFileValues = response.data.map(
                ({ name, path, _id, ...other }) => ({
                name,
                path: url.SERVER_URL + path,
                filepath: path,
                _id,
                })
            );

            //check old file exist or not if exist then new file will concat with old file
            if (uploadedFiles.length == 0) {
                setuploadedFiles(uploadedFileValues);
            } else {
                let fileArr = uploadedFiles.concat(uploadedFileValues);
                setuploadedFiles(fileArr);
            }
            /*----- assign uploaded files with path for display end ------*/

            /*----- assign uploaded file ids start ------*/
            const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

            //check old file ids are exist or not.If exist then new id will concat with old id
            if (uploadedFileIds.length == 0) {
                setuploadedFileIds(fileIds);
            } else {
                let fileArr = uploadedFileIds.concat(fileIds);
                setuploadedFileIds(fileArr);
            }
            /*----- assign uploaded file ids end ------*/
            resetFile();
            }
        } else {
            setFileIsBig(true);
        }
        } catch (error) {
        console.log(error.message);
        }
    };

    // function for clear file value
    const resetFile = () => {
        setUploadedFileCount(0);
        const file = document.getElementById("faqUploadFile");
        if (file) {
        file.value = null;
        } else {
        return;
        }
    };

    //function for delete file from upload stack
    const closeFileHandler = (index) => {
        const closedFile = [...uploadedFiles];
        const removedFileId = [...uploadedFileIds];

        closedFile.splice(index, 1);
        removedFileId.splice(index, 1);

        setuploadedFiles(closedFile);
        setuploadedFileIds(removedFileId);

        resetFile();
    };

    return (
        <>
        <div className="modal fade" 
            id="askQuestion" 
            tabIndex="-1" 
            aria-labelledby="askQuestionLabel" 
            aria-hidden="true" 
            data-bs-backdrop="static"
            >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header p-4 pb-0">
                        <h1 className="modal-title fs-5" id="askQuestionLabel">Ask Question</h1>
                        <button type="button" className="btn-close m-0 shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-group mb-3">
                                <input type="text" 
                                    className="form-control shadow-none" 
                                    placeholder="Add your Subject" 
                                    value={faqsubject}
                                    onChange={(e)=>{
                                        setFaqSubject(e.target.value);
                                    }} 
                                />

                                {faqSubjectError && <p className="text-danger">Subject is required</p>} 

                            </div>
                            <div className="form-group mb-4">
                                <textarea name="description" id="description" 
                                className="form-control shadow-none" 
                                rows="5" 
                                value={faqdescription}
                                onChange={(e) => setFaqDescription(e.target.value)}
                                ></textarea>

                                {faqDescriptionError && <p className="text-danger">Description is required</p>}

                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="attachFile" className="d-block fw-bold mb-2">Attach File</label>
                                <ul className="upload-files d-flex flex-wrap gap-2 text-center">

                                    <li className="upload">
                                        <label htmlFor="uploadFile" className="h-100 d-flex flex-column align-items-center justify-content-center p-3 position-relative cursor-pointer">
                                            <input 
                                                id="faqUploadFile" 
                                                type="file" 
                                                className="upload_fileBtn"
                                                multiple
                                                onChange={fileUploadHandler}
                                            />
                                            <span className="d-block material-icons-outlined mb-2">upload</span>
                                            <p className="name mb-1">Upload</p>
                                            <p className="size">Max 20mb doc/mp4</p>
                                        </label>
                                    </li>

                                    {uploadedFiles.length > 0 && uploadedFiles.map((file, index) => (
                                        
                                        
                                        <li key={index} 
                                            className="file d-flex flex-column align-items-center justify-content-center p-3 position-relative">
                                            <Link to="#" 
                                            className="delete position-absolute top-0 end-0 mt-2 me-2"
                                            onClick={() => closeFileHandler(index)}
                                            >
                                            
                                                <span className="d-block material-icons-outlined">delete</span>
                                            </Link>
                                            <img src="images/pdf-icon.svg" alt="MyResume.pdf" className="img-fluid mb-2" />
                                            <p className="name mb-1">{file.name}</p>
                                            <p className="size">1.5mb</p>
                                        </li>

                                    
                                    ))}

                                </ul>
                            </div>
                            <div className="action">
                                <button type="button" 
                                className="basic_btn ylw_btn w-auto"
                                onClick={handleSubmit}
                                >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <SentModal />
        </>
    );
};

export default AskQuestion;