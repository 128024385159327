/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { GlobalIndraInfo } from "App";
import { useTranslation } from "react-i18next";

//import components
import ContactModal from "components/ChampionsDetailsComponents/modals/ContactModal";

// import Gateway for API fetch call
import { getData } from "utils/Gateway";

// import all URLs from url_helper
import * as url from "helpers/url_helper";
/* --------------------------------------------------------------*/

// import image assests here
import defaultUser from "assets/HomeAssets/Images/default-user.png";
import expert1 from "assets/ChampionsDetailsAssets/images/expert9.png";
import social1 from "assets/ChampionsDetailsAssets/svg/social1.svg";
import social2 from "assets/ChampionsDetailsAssets/svg/social2.svg";
import social3 from "assets/ChampionsDetailsAssets/svg/social3.svg";
import social4 from "assets/ChampionsDetailsAssets/svg/social4.svg";
import AddTagsModal from "components/ChampionsDetailsComponents/modals/AddTagModal";
import ChampionConnectThankModal from "components/ChampionsDetailsComponents/modals/ChampionConnectThankModal";

const ChampionsDetailsTopSection = () => {
  const token = localStorage.getItem("token_v2");
  const userInfo = JSON.parse(localStorage.getItem("userInfo_v2"));

  const loggedInUserID = localStorage.getItem("userID_v2");

  const params = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  // getting search params from useContext
  const {
    getChampionName,
    getConnectionStatus,
    reloadChampionDetails,
    setReloadChampionDetails,
  } = useContext(GlobalIndraInfo);

  //initial for champion data
  const [championData, setChampionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // function for get champion details
  const getChampionsData = async () => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl_V2 +
      url.API_CHAMPION_BANNER +
      `/${params.id}?token=${token}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setChampionData(response.data);
          if (response.data.name && response.data.surname) {
            let championName = response.data.name + " " + response.data.surname;
            // sending the name to the useContext API
            getChampionName(championName);
          }
          // send champion data
          getConnectionStatus(response.data.connectstatus);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  // function for calling API after closing add tag modal
  const afterModalClose = () => {
    getChampionsData(params.id);
  };

  useEffect(() => {
    if (reloadChampionDetails) {
      getChampionsData(params.id);
      setReloadChampionDetails(false);
    }
  }, [reloadChampionDetails]);

  // useEffect from here
  useEffect(() => {
    if (params.id) {
      getChampionsData();
    }
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <section className="course_details_sec placeholder-glow">
          {/* ---- glow more area start ---- */}
          <div className="container">
            <div className="course_detailsBx">
              <div className="topdetails_left">
                <div className="global_details_outer">
                  <div className="glbl_detailsPic">
                    <span
                      className="placeholder rounded-circle"
                      style={{ width: "90px", height: "90px" }}
                    ></span>
                  </div>
                  <div className="glbl_detailstext">
                    <div className="details_usrName_hdng">
                      <span
                        className="placeholder d-block rounded-3 mb-2"
                        style={{ width: "215px", height: " 26px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-md rounded-3"
                        style={{ width: "100px" }}
                      ></span>
                    </div>
                    <div className="details_tags expert_details">
                      <span
                        className="placeholder placeholder-md rounded-3 me-3"
                        style={{ width: " 142px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-md rounded-3 me-3"
                        style={{ width: "75px" }}
                      ></span>
                      <span
                        className="placeholder placeholder-md rounded-3"
                        style={{ width: "90px" }}
                      ></span>
                    </div>
                    <div className="hire_btn">
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "108px", height: "50px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="topdetails_right block_rightPnl">
                <div className="asign_top">
                  <div className="exprt_share social_share">
                    <ul>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: " 40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                      <li>
                        <span
                          className="placeholder"
                          style={{ width: "40px", height: "40px" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---- glow more area end ---- */}
        </section>
      ) : (
        <section className="course_details_sec">
          <div className="container">
            {championData == null || championData.length === 0 ? (
              <div className="course_detailsBx">
                <div className="topdetails_left">
                  <div className="global_details_outer">
                    <div className="glbl_detailsPic">
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "90px", height: "90px" }}
                      ></span>
                    </div>
                    <div className="glbl_detailstext">
                      <div className="details_usrName_hdng">
                        <span
                          className="placeholder d-block rounded-3 mb-2"
                          style={{ width: "215px", height: " 26px" }}
                        ></span>
                        <span
                          className="placeholder placeholder-md rounded-3"
                          style={{ width: "100px" }}
                        ></span>
                      </div>
                      <div className="details_tags expert_details">
                        <span
                          className="placeholder placeholder-md rounded-3 me-3"
                          style={{ width: " 142px" }}
                        ></span>
                        <span
                          className="placeholder placeholder-md rounded-3 me-3"
                          style={{ width: "75px" }}
                        ></span>
                        <span
                          className="placeholder placeholder-md rounded-3"
                          style={{ width: "90px" }}
                        ></span>
                      </div>
                      <div className="hire_btn">
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "108px", height: "50px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="topdetails_right block_rightPnl">
                  <div className="asign_top">
                    <div className="exprt_share social_share">
                      <ul>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "40px", height: " 40px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </li>
                        <li>
                          <span
                            className="placeholder"
                            style={{ width: "40px", height: "40px" }}
                          ></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="course_detailsBx">
                {/* --------------------------- Left Section --------------------------- */}
                <div className="topdetails_left">
                  <div className="global_details_outer">
                    <div className="glbl_detailsPic">
                      <img
                        src={
                          championData.userimagepath === ""
                            ? defaultUser
                            : url.SERVER_URL + championData.userimagepath
                        }
                        alt=""
                      />
                    </div>
                    <div className="glbl_detailstext">
                      <div className="details_usrName_hdng">
                        <h3>
                          {championData.name} {championData.surname}
                        </h3>
                        <h5>{championData.position}</h5>
                      </div>
                      <div className="details_tags flex-wrap gap-3 expert_details">
                        <div className="jb_rating star_color">
                          <i
                            className="material-icons-outlined"
                            style={{ color: "#FBBB21" }}
                          >
                            star
                          </i>
                          <span style={{ color: "#FBBB21" }}>
                            {championData.rating}
                          </span>
                          <h6 style={{ color: "#FBBB21" }}>
                            ({championData.reviews}
                            <em> {t("Reviews")}</em>)
                          </h6>
                        </div>

                        <div
                          className="course_text winner-of-posts d-inline-flex align-items-center text-white p-0 m-0"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push("/activity", {
                              id: params.id,
                            })
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            // className="me-2"
                            width="16"
                            height="16"
                            fill="#FBBB21"
                            className="bi bi-trophy-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
                          </svg>
                          <span className="text-white mx-1">
                            {t("Winner of Post")}{" "}
                            <span> {championData.postwinner} </span>
                          </span>
                        </div>

                        {/* <div className="course_text">
                          <h4 style={{ color: "#ffff" }}>
                            {championData.address}
                          </h4>
                        </div> */}
                        {/* <div className="course_text">
                          <i className="material-icons-outlined"> people</i>
                          <h4 style={{ color: "#ffff" }}>
                            {championData.enrolledCount} Enrolled
                          </h4>
                        </div> */}
                      </div>

                      {championData.isconnect ? (
                        <div className="hire_btn pt-3 mt-4 border-top pe-4">
                          {championData.connectstatus ? null : (
                            <p className="text-white join-event-request d-flex">
                              <i className="material-icons me-2">error</i>
                              <span className="text-white">
                                {t("Pending Approval")} <br />
                                {t("Date")}{" "}
                                {championData.requestdate && (
                                  <span className="text-blue">
                                    {championData.requestdate}
                                  </span>
                                )}
                              </span>
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className="hire_btn pt-3 mt-4 border-top pe-4">
                          {userInfo._id.toString() ===
                          championData._id.toString() ? null : (
                            <Link
                              to="#"
                              className="basic_btn ylw_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#contact_owner"
                            >
                              {t("Connect")}
                            </Link>
                          )}
                        </div>
                      )}

                      {/* {championData.connectdate && (
                        <p className="join_text">
                          {t("Connect Date")}: {championData.connectdate}
                        </p>
                      )}

                      {championData.isconnect && (
                        <div className="champion_connect">
                          {championData.connectstatus ? null : (
                            <p className="join_text">
                              {t("Connection Status")}:{t("Pending Approval")}
                            </p>
                          )}
                        </div>
                      )} */}

                      {/* <p
                        style={{ cursor: "pointer" }}
                        className="join_text"
                        onClick={() =>
                          history.push("/activity", {
                            id: params.id,
                          })
                        }
                      >
                        {t("Winner of posts")}: {championData.postwinner}
                      </p> */}

                      {/* {championData.isconnect ||
                      championData.id == loggedInUserID ? null : (
                        <div className="hire_btn">
                          <Link
                            to="#"
                            className="basic_btn ylw_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#contact_owner"
                          >
                            {t("Connect")}
                          </Link>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>

                {/* --------------------------- Right Section --------------------------- */}
                <div className="topdetails_right block_rightPnl">
                  <div className="asign_top">
                    <div className="exprt_share social_share">
                      <ul>
                        {/* <li>
                          <Link to="#">
                            <img src={social1} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={social2} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={social3} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={social4} alt="" />
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#champtag_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="#">
                            <i className="material-icons-outlined">share</i>
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  {/* --------- Tags list and dropdown start --------- */}
                  <div className="tags_menu">
                    {/* -------- Top 3 tags here -------- */}
                    {championData.tags.length > 0 ? (
                      <ul className="tags_list">
                        {championData.tags.slice(0, 3).map((tags, index) => {
                          return (
                            <li key={index}>
                              <Link to="#">{tags.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}

                    {/* -------- Remaining tags here in dropdown --------- */}
                    {championData.tags.length > 3 ? (
                      <div className="tags_drop dropdown">
                        <Link to="#" data-bs-toggle="dropdown">
                          <span className="material-icons-outlined">
                            expand_more
                          </span>
                        </Link>
                        {championData.tags.length > 0 ? (
                          <ul className="dropdown-menu">
                            {championData.tags
                              .slice(3, championData.tags.length)
                              .map((tags, index) => {
                                return (
                                  <li key={index}>
                                    <Link to="#">{tags.label}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  {/* --------- Tags list and dropdown end --------- */}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
      <ContactModal
        championData={championData}
        getChampionsData={getChampionsData}
      />
      <AddTagsModal afterModalClose={afterModalClose} />
      <ChampionConnectThankModal />
    </>
  );
};

export default ChampionsDetailsTopSection;
